import React from 'react';

import { BsArrowRight } from 'react-icons/bs';

const TextButton = ({ label, className, onClick }) => {
	return (
		<span
			className={`ease-in-out transition-transform transform hover:translate-x-2 flex gap-2 items-center text-black-300 hover:text-purple-500 font-medium text-base cursor-pointer ${className}`}
			onClick={onClick}
		>
			{label}
			<BsArrowRight />
		</span>
	);
};

export default TextButton;
