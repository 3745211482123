import React from 'react';
import { useQuery } from '@tanstack/react-query';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

// import { removeHtml } from '../utils/removeHtml';
import { getFileUrl } from '../utils/imageURL';
import LoadingSpinner from './LoadingSpinner';

// services
import { getCompanies } from '../services/industries/industries.services';
// import { getCMS } from '../services/home/home.services';

function PreviousBtn(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block' }}
			onClick={onClick}
		>
			<AiOutlineLeft size={24} color="#5D5A88" />
		</div>
	);
}

function NextBtn(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: 'block' }}
			onClick={onClick}
		>
			<AiOutlineRight size={24} color="#5D5A88" />
		</div>
	);
}

const DEFAULT_PAGE_SIZE = 4;

const Company = () => {
	const { data: companies, isPending: companiesLoading } = useQuery({
		queryKey: ['companies'],
		queryFn: () => getCompanies(0, DEFAULT_PAGE_SIZE),
	});

	if (companiesLoading) {
		return <LoadingSpinner />;
	}

	const settings = {
		nextArrow: <NextBtn />,
		prevArrow: <PreviousBtn />,
		dots: false,
		infinite: true,
		adaptiveHeight: false,
		autoplay: true,
		autoplaySpeed: 1500,
		pauseOnHover: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		initialslide: 0,
		centerMode: true,
		centerPadding: '60px',
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
					infinite: true,
					centerMode: true,
					centerPadding: '40px',
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
					infinite: true,
					centerMode: true,
					centerPadding: '40px',
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: '40px',
				},
			},

			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: '40px',
				},
			},
		],
	};

	return (
		<div className="flex flex-col items-center text-center max-container padding-x pt-28 pb-24 w-full">
			<div className="w-full">
				<p className="top-title">Invested Companies</p>
				<h3 className="title">
					{/* {companyCMS?.[0]?.title} */}
					Trusted by 1000+ Companies
				</h3>

				{/* Slick Slider */}
				<div className="mt-16 padding-x">
					<Slider
						{...settings}
						className="w-full flex mx-auto justify-center items-center gap-[132px]"
					>
						{companies?.map((company) => {
							const { id, image, linkedin } = company;
							return (
								<div
									key={id}
									className="w-full !grayscale hover:!grayscale-0 cursor-pointer"
									style={{ textAlign: 'center' }}
								>
									<a href={linkedin} className="outline-none">
										<img
											src={getFileUrl(image)}
											alt="company"
											className="object-cover h-full"
										/>
									</a>
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default Company;
