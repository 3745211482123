import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Button } from '../components';

import { removeHtml } from '../utils/removeHtml';
import { getFileUrl } from '../utils/imageURL';

const Hero = ({ homeCMS }) => {
	const navigate = useNavigate();
	// const sectionRef = useRef(null);

	// navigate to /industries
	const navigateToIndustries = () => {
		window.scrollTo(0, 0);
		navigate('/industries');
	};

	// navigate to /contact
	const navigateToContact = () => {
		window.scrollTo(0, 0);
		navigate('/contact');
	};

	return (
		<div
			className="flex lg:flex-row flex-col gap-[77px] pt-[92px] pb-[102px] padding-x max-container"
			style={{ overflowX: 'hidden' }}
		>
			<motion.div
				initial={{ opacity: 0, x: '-100vw' }} // initial position outside the viewport
				animate={{ opacity: 1, x: 0 }} // Final position at 0 offset
				whileInView={{ opacity: 1 }}
				transition={{ duration: 1.6, ease: 'easeInOut' }}
				className="w-full lg:w-2/4"
			>
				<h1 className="heading-text w-full text-left">
					{homeCMS?.[0]?.title}
					<br />
					<span className="gradient-text">Exceptional Returns</span>
				</h1>
				<p className="text-lg pt-4 lg:w-11/12">
					{removeHtml(homeCMS?.[0]?.description)}
				</p>
				<div className="flex mt-10 gap-3 items-center md:gap-9">
					<Button label="See Industries" onClick={navigateToIndustries} />
					<p
						className="max-sm:text-sm text-purple-400 hover:underline button-transition cursor-pointer font-medium leading-6"
						onClick={navigateToContact}
					>
						Talk to an Expert
					</p>
				</div>
			</motion.div>
			<motion.div
				initial={{ opacity: 0, x: '100vw' }} // Initial position outside the viewport
				animate={{ opacity: 1, x: 0 }} // Final position at 0 offset
				transition={{
					duration: 1.6,
					// bounce: 0.25,
					ease: 'easeInOut',
					delay: 0.5,
				}}
				className="sm:flex-1 flex w-full lg:w-2/4"
			>
				<motion.img
					src={getFileUrl(homeCMS?.[0]?.image)}
					alt="hero background"
					className="object-cover"
					// initial={{ opacity: 0, x: '100vw' }} // Initial position outside the viewport
					// animate={{ opacity: 1, x: 0 }} // Final position at 0 offset
					// transition={{
					// 	duration: 1,
					// 	type: 'spring',
					// 	stiffness: 120,
					// 	delay: 0.5,
					// }}
				/>
			</motion.div>
		</div>
	);
};

export default Hero;
