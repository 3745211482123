import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import TextButton from '../components/TextButton';

//services
import { getIndustries } from '../services/industries/industries.services';

import { removeHtml } from '../utils/removeHtml';
import LoadingSpinner from '../components/LoadingSpinner';
import { getFileUrl } from '../utils/imageURL';

const DEFAULT_PAGE_SIZE = 4;

const Industries = ({ homeCMS }) => {
	const navigate = useNavigate();

	const navigateToIndustry = (slug, id) => {
		window.scrollTo(0, 0);
		navigate(`/industries/${slug}/${id}`);
	};

	// Queries
	const { data: industries, isPending: industriesLoading } = useQuery({
		queryKey: ['industries'],
		queryFn: () =>
			getIndustries(0, DEFAULT_PAGE_SIZE).then((data) => data.reverse()),
	});

	if (industriesLoading) {
		return <LoadingSpinner />;
	}

	return (
		<div className="h-full padding-y-30">
			<div className="flex flex-col items-center text-center padding-x">
				<p className="top-title">Industries</p>
				<h3 className="title">{homeCMS?.[6]?.title}</h3>
				<p className="sub-title w-full lg:max-w-[602px] mb-16">
					{removeHtml(homeCMS?.[6]?.description)}
				</p>
			</div>

			<div className="max-w-screen max-h-max xl:relative">
				<div className="max-w-screen xl:flex justify-center xl:relative max-h-full">
					<img
						src={getFileUrl(homeCMS?.[6]?.image)}
						alt="Banner"
						className="object-contain lg:object-cover w-full max-h-full md:h-[502px] lg:w-full lg:h-auto"
					/>
					<div className="max-container xl:absolute xl:-bottom-[45%] flex flex-col lg:flex-row gap-10 md:gap-8 w-full padding-x py-8 md:px-[60px] md:py-[62px] xl:border bg-white rounded-xl">
						{industries.map((industry, index) => {
							const { id, title, shortDescription, slug } = industry;
							return (
								<div
									key={id}
									className="cursor-pointer group"
									onClick={() => {
										navigateToIndustry(slug, id);
									}}
								>
									<span
										className={`py-2 px-5 rounded-lg text-2xl font-semibold bg-white-400 text-purple-500 transition ease-in-out group-hover:bg-purple-500 group-hover:text-white
										`}
									>
										{index + 1}
									</span>
									<h3 className="text-3xl font-semibold mt-5">{title}</h3>
									<div className="text-lg text-black-300 mt-1 mb-5">
										<p className="truncated-text">
											{removeHtml(shortDescription)}
										</p>
									</div>
									<TextButton
										label="Learn more"
										className="transition-transform transform group-hover:translate-x-2 group-hover:text-purple-500"
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Industries;
