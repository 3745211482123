import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Industries, AboutUs, Contact, IndustryDetail } from './pages';
import { Nav, Footer } from './components';

import { Helmet, HelmetProvider } from 'react-helmet-async';

const App = () => {
	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>PSCUBE</title>
				</Helmet>
				<Nav />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/industries" element={<Industries />} />
					<Route
						exact
						path="/industries/:slug/:id"
						element={<IndustryDetail />}
					/>
					<Route exact path="/about" element={<AboutUs />} />
					<Route exact path="/contact" element={<Contact />} />
				</Routes>
				<Footer />
			</HelmetProvider>
		</>
	);
};

export default App;
