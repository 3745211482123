import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { getCMS } from '../services/home/home.services';
import { removeHtml } from '../utils/removeHtml';
import { getFileUrl } from '../utils/imageURL';
import LoadingSpinner from './LoadingSpinner';

const DEFAULT_PAGE_SIZE = 4;
const contact_slug = 'contact-page';

const Contact = () => {
	const navigate = useNavigate();

	const navigateToContact = () => {
		window.scrollTo(0, 0);
		navigate('/contact');
	};

	const navigateToIndustries = () => {
		window.scrollTo(0, 0);
		navigate('/industries');
	};

	const { data: contactCMS, isPending: contactCMSLoading } = useQuery({
		queryKey: ['contact-cms', contact_slug],
		queryFn: () => getCMS(contact_slug, DEFAULT_PAGE_SIZE),
	});

	if (contactCMSLoading) {
		return <LoadingSpinner />;
	}

	return (
		<div
			className="flex flex-1 flex-col justify-center items-center w-full h-full padding-x"
			style={{
				backgroundImage: `url(${getFileUrl(contactCMS?.[1]?.image)})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className="py-28">
				<div className="lg:max-w-[602px] text-center">
					<h2 className="font-semibold text-4xl">{contactCMS?.[0]?.title}</h2>
					<p className="font-normal text-lg text-black-300 pt-4">
						{removeHtml(contactCMS?.[0]?.description)}
					</p>
				</div>
				<div className="flex justify-center gap-4 pt-8">
					<button
						className="button-transition button-purple bg-purple-500 hover:bg-purple-300 hover:text-white flex "
						onClick={navigateToContact}
					>
						Get In Touch
					</button>
					<button
						className="button-transition button-transparent hover:underline hover:border-purple-300"
						onClick={navigateToIndustries}
					>
						View Industries
					</button>
				</div>
			</div>
		</div>
	);
};

export default Contact;
