import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Company, Contact, IndustryContent } from '../components';

import { Disclosure, Transition } from '@headlessui/react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { FaRegCommentDots } from 'react-icons/fa6';
import { TbMessage2Question } from 'react-icons/tb';

//services
import {
	getIndustries,
	getIndustry,
} from '../services/industries/industries.services';
import LoadingSpinner from '../components/LoadingSpinner';
import { getCMS, getSiteSettings } from '../services/home/home.services';

import { Tab } from '@headlessui/react';
import { AiOutlineRight } from 'react-icons/ai';

import { removeHtml } from '../utils/removeHtml';
import { getFileUrl } from '../utils/imageURL';
import customHtmlParser from '../utils/customHtmlParser';

const DEFAULT_PAGE_SIZE = 4;
const investment_slug = 'investment-page';
const question_slug = 'frequent-questions';

const IndustryDetail = () => {
	const [selectedTab, setSelectedTab] = useState(null);

	const navigate = useNavigate();

	const { slug } = useParams();

	const navigateToContact = () => {
		window.scrollTo(0, 0);
		navigate('/contact');
	};

	const navigateToIndustry = (slug, id) => {
		navigate(`/industries/${slug}/${id}`);
	};

	// Queries
	const { data: industries, isPending: industriesLoading } = useQuery({
		queryKey: ['industries'],
		queryFn: () =>
			getIndustries(0, DEFAULT_PAGE_SIZE).then((data) => data.reverse()),
	});
	const { data: siteSettings, isPending: siteSettingsLoading } = useQuery({
		queryKey: ['site-settings'],
		queryFn: () => getSiteSettings(),
	});
	const { data: investmentCMS, isPending: investmentCMSLoading } = useQuery({
		queryKey: ['company-cms', investment_slug],
		queryFn: () => getCMS(investment_slug, 4),
	});
	const { data: industry, isPending: industryLoading } = useQuery(
		{
			queryKey: ['industry', slug],
			queryFn: () => getIndustry(slug, 1),
		}
		// { enabled: !!id }
	);
	const { data: questions, isPending: questionsLoading } = useQuery({
		queryKey: ['questions', question_slug],
		queryFn: () => getCMS(question_slug, 4),
	});

	if (
		industriesLoading ||
		siteSettingsLoading ||
		investmentCMSLoading ||
		industryLoading ||
		questionsLoading
	) {
		return <LoadingSpinner />;
	}

	const investmentKeys = investmentCMS?.slice(1, 4);

	return (
		<>
			{/* Hero Section */}
			<div className="bg-white-400">
				<div className="max-container padding-x">
					<IndustryContent industry={industry} />
				</div>
			</div>
			{/* Main content */}
			<div className="flex gap-16 max-container padding-x pt-16">
				<div className="hidden md:block md:w-4/12">
					<h4 className="text-2xl font-semibold">Other Sectors</h4>
					<ul className="pt-8">
						<Tab.Group vertical>
							<Tab.List>
								{industries?.map((industry) => {
									const { id, slug, title } = industry;
									return (
										<Tab
											key={id}
											className="p-0 flex w-full outline-none border-none"
											onClick={() => {
												navigateToIndustry(slug, id);
												setSelectedTab(id);
											}}
										>
											{({ selected }) => (
												<span
													className={`w-full flex items-center gap-3 text-left text-lg cursor-pointer font-medium border-b border-white-300 pl-2 py-4 px-0 ${
														selected ? ' text-purple-500' : 'text-black'
													}`}
												>
													{selected && <AiOutlineRight />}
													{title}
												</span>
											)}
										</Tab>
									);
								})}
							</Tab.List>
						</Tab.Group>
					</ul>
					<div className="my-12 bg-purple-100 rounded-xl">
						<div className="portfolio-card">
							<h3 className="text-3xl text-purple-500 font-semibold">
								View Portfolio
							</h3>
							<p className="text-purple-500 leading-6 pt-4 pb-8">
								This is the first benefit of IT sector investment.
							</p>
							<button className="button-transition button-purple-hover bg-purple-500 text-white-400 rounded-xl font-semibold leading-[18px]">
								Download Now
							</button>
						</div>
					</div>
					<div className="contact-card">
						<span className="w-[56px] h-[56px]">
							<FaRegCommentDots style={{ width: '56px', height: '56px' }} />
						</span>
						<h3 className="text-3xl font-semibold py-4">Let's Talk</h3>
						<p className="leading-6">{siteSettings?.phone}</p>
						<p className="pt-1 pb-8">{siteSettings?.email2}</p>
						<button
							className="button-transition hover:bg-[#e3e3e5] hover:border-[#e3e3e5] bg-white-400 text-purple-500 rounded-xl font-bold leading-[18px]"
							onClick={navigateToContact}
						>
							Contact Us
						</button>
					</div>
				</div>
				<div className="w-full md:w-8/12">
					<h3 className="title">Why Choose PS Cube for {industry?.title}?</h3>
					<div className="w-full pt-8">
						<img
							src={getFileUrl(industry?.image)}
							className="rounded-xl w-full h-[424px] object-cover"
							alt="desktop"
						/>
					</div>
					<div className="sub-title py-8">
						{industry?.description
							? customHtmlParser(industry?.description)
							: ''}
					</div>

					{/* Accordion */}
					<h3 className="text-[32px] font-semibold leading-12 pb-6 border-b border-white-300">
						Frequent Questions
					</h3>

					{questions?.map((item, index) => (
						<div className="border-b border-white-300 py-5" key={index}>
							<Disclosure>
								{({ open }) => (
									<>
										<Disclosure.Button className="flex items-center w-full justify-between p-0 text-lg font-medium">
											<span>{item.title}</span>
											{open ? <AiOutlineMinus /> : <AiOutlinePlus />}
										</Disclosure.Button>
										<Transition
											show={open}
											enter="transition duration-100 ease-out"
											enterFrom="transform scale-95 opacity-0"
											enterTo="transform scale-100 opacity-100"
											leave="transition duration-75 ease-out"
											leaveFrom="transform scale-100 opacity-100"
											leaveTo="transform scale-95 opacity-0"
										>
											<Disclosure.Panel className="sub-title pt-4" static>
												{removeHtml(item.description)}
											</Disclosure.Panel>
										</Transition>
									</>
								)}
							</Disclosure>
						</div>
					))}

					<div className="flex justify-end gap-2 mt-8">
						<span className="w-[40px] h-[40px]">
							<TbMessage2Question
								style={{ height: '40px', width: '40px', color: '#606060' }}
							/>
						</span>
						<span className="cursor-pointer">
							<p className="text-black-300 leading-6">More question?</p>
							<p className="text-purple-500 leading-6 font-semibold">
								Get a support Now
							</p>
						</span>
					</div>
				</div>
			</div>

			{/* Financial Investments */}
			<div className="flex flex-col items-center gap-14 max-container padding-x py-[110px]">
				<div className="flex flex-col items-center max-w-screen-sm text-center">
					<p className="top-title">Steps</p>
					<h3 className="title">{investmentCMS?.[0]?.title}</h3>
					<p className="sub-title">
						{removeHtml(investmentCMS?.[0]?.description)}
					</p>
				</div>
				<div className="flex flex-col lg:flex-row gap-10 max-lg:gap:0 justify-between">
					<motion.div
						whileInView={{ x: [-100, 0], opacity: [0, 1] }}
						transition={{ duration: 0.5, ease: 'easeInOut' }}
						className="flex w-full lg:w-7/12"
					>
						<img
							src={getFileUrl(investmentCMS?.[0]?.image)}
							className="rounded-xl"
							alt="investment"
						/>
					</motion.div>
					<motion.ul
						whileInView={{ x: [100, 0], opacity: [0, 1] }}
						transition={{ duration: 1.6, ease: 'easeInOut' }}
						className="flex flex-col justify-center gap-9 w-full lg:w-5/12"
					>
						{investmentKeys?.map((investmentKey) => {
							const { id, title, image, description } = investmentKey;
							return (
								<li className="flex gap-3 md:gap-8" key={id}>
									<span className="flex items-center justify-center rounded-xl bg-purple-500 w-[50px] h-[50px]">
										<img src={getFileUrl(image)} alt="investment icon" />
									</span>
									<div className="w-10/12">
										<h3 className="text-2xl font-semibold">{title}</h3>
										<p className="sub-title pt-3">{removeHtml(description)}</p>
									</div>
								</li>
							);
						})}
					</motion.ul>
				</div>
			</div>
			<Company />
			<Contact />
		</>
	);
};

export default IndustryDetail;
