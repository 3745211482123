import React, { useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Button, Contact } from '../components';

import LoadingSpinner from '../components/LoadingSpinner';
import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';

// import { Helmet } from 'react-helmet-async';

// services
// import { getCMSPageTitle } from '../services/home/home.services';
import { getIndustries } from '../services/industries/industries.services';
import { GiConsoleController } from 'react-icons/gi';

const DEFAULT_PAGE_SIZE = 4;

const Industries = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const containerRefs = useRef([]);

	const getButtonLabel = () => {
		if (location.pathname === '/industries') {
			return 'Know More';
		} else if (location.pathname.startsWith('/industries/')) {
			return 'Get Started';
		}
	};

	const navigateToIndustry = (slug, id) => {
		// window.scrollTo(0, 0);
		navigate(`/industries/${slug}/${id}`);
	};

	// Queries
	const { data: industries, isPending: industriesLoading } = useQuery({
		queryKey: ['industries'],
		queryFn: () =>
			getIndustries(0, DEFAULT_PAGE_SIZE).then((data) => data.reverse()),
	});

	// const { data: pageTitleCMS, isPending: pageTitleCMSLoading } = useQuery(
	// 	['cms-pageTitle', slug],
	// 	() => getCMSPageTitle(slug, DEFAULT_PAGE_SIZE)
	// );

	// useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		(entries) => {
	// 			entries.forEach((entry) => {
	// 				if (entry.isIntersecting) {
	// 					entry.target.classList.add('animate');
	// 				}
	// 			});
	// 		},
	// 		{ threshold: 0.3 }
	// 	);

	// 	containerRefs.current.forEach((ref) => {
	// 		observer.observe(ref);
	// 	});

	// 	return () => {
	// 		containerRefs.current.forEach((ref) => {
	// 			observer.unobserve(ref);
	// 		});
	// 	};
	// }, [industries]);

	// if (industriesLoading) {
	// 	return <LoadingSpinner />;
	// }

	console.log(industries);

	const scaleVariants = {
		whileInView: {
			scale: [0, 1],
			opacity: [0, 1],
			transition: {
				duration: 1,
				ease: 'easeInOut',
			},
		},
	};

	// const isMobile = useMediaQuery('(min-width: 768px)');
	const isMobile = window.innerWidth <= 768;

	return (
		<>
			{/* <Helmet>
				<title>{`PSCUBE | ${pageTitleCMS?.title}`}</title>
			</Helmet> */}
			<section
				className="max-container padding-x"
				style={{ overflow: 'hidden' }}
			>
				{industries?.map((industry, index) => {
					const { id, slug, title, shortDescription, icon } = industry;

					const isSecondItem = index === 1;
					return (
						<div
							key={id}
							// ref={(el) => (containerRefs.current[index] = el)}
							className={`flex flex-col justify-center items-center gap-10 padding-y animate
							md:flex-row md:justify-between md:gap-0 
							${isSecondItem ? 'flex-col-reverse md:flex-row-reverse' : ''}`}
						>
							<motion.div
								whileInView={{
									x: isSecondItem ? [160, 0] : [-160, 0],
									opacity: [0, 1],
								}}
								transition={{ duration: 0.5 }}
								className="w-full md:w-2/4 lg:w-2/4 xl:w-2/5"
							>
								<h3 className="heading-text">{title}</h3>
								<p className="sub-title pt-4 pb-10">
									{removeHtml(shortDescription)}
								</p>
								<Button
									label={getButtonLabel()}
									onClick={() => {
										navigateToIndustry(slug, id);
									}}
								/>
							</motion.div>
							<motion.div
								variant={scaleVariants}
								whileInView={scaleVariants.whileInView}
								className="w-full md:w-2/4"
							>
								<motion.img src={getFileUrl(icon)} alt="it industry" />
							</motion.div>
						</div>
					);
				})}
			</section>
			<Contact />
		</>
	);
};

export default Industries;
