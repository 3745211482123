import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import Button from '../Button';

import { removeHtml } from '../../utils/removeHtml';
import { getFileUrl } from '../../utils/imageURL';

const IndustryContent = ({ industry }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const getButtonLabel = () => {
		if (location.pathname === '/industries') {
			return 'Know More';
		} else if (location.pathname.startsWith('/industries/')) {
			return 'Get Started';
		}
	};

	const navigateToITIndustry = (slug) => {
		// Redirect to contact page only if not on the home page
		window.scrollTo(0, 0);
		if (location.pathname !== '/industries') {
			// window.scrollTo(0, 0);
			navigate('/contact');
		} else {
			// if on the home page, navigate to the specific industry
			// window.scrollTo(0, 0);
			navigate(`/industries/${slug}`);
		}
	};

	return (
		<div
			className="flex flex-col md:flex-row justify-center md:justify-between items-center gap-10 md:gap-0 pt-20 pb-28"
			style={{ overflowX: 'hidden' }}
		>
			<motion.div
				initial={{ opacity: 0, x: '-100vw' }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 1.6, ease: 'easeInOut' }}
				className="w-full md:w-2/4 lg:w-2/4 xl:w-2/5"
			>
				<h3 className="heading-text">{industry?.title}</h3>
				<p className="sub-title pt-4 pb-10">
					{removeHtml(industry?.shortDescription)}
				</p>
				<Button label={getButtonLabel()} onClick={navigateToITIndustry} />
			</motion.div>
			<motion.div
				initial={{ opacity: 0, x: '100vw' }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 1.6, ease: 'easeInOut', delay: 0.5 }}
				className="w-full md:w-2/4"
			>
				<motion.img src={getFileUrl(industry?.icon)} alt="it industry" />
			</motion.div>
		</div>
	);
};

export default IndustryContent;
