import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { footerLinks } from '../constants';

import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { getCMS, getSiteSettings } from '../services/home/home.services';
import { removeHtml } from '../utils/removeHtml';
import LoadingSpinner from './LoadingSpinner';
import { getFileUrl } from '../utils/imageURL';

const DEFAULT_PAGE_SIZE = 4;
const footer_slug = 'footer-page';

const Footer = () => {
	// Queries
	const { data: siteSettings, isPending: siteSettingsLoading } = useQuery({
		queryKey: ['site-settings'],
		queryFn: () => getSiteSettings(),
	});
	const { data: footerCMS, isPending: footerCMSLoading } = useQuery({
		queryKey: ['footer-cms', footer_slug],
		queryFn: () => getCMS(footer_slug, DEFAULT_PAGE_SIZE),
	});

	if (siteSettingsLoading || footerCMSLoading) {
		return <LoadingSpinner />;
	}

	return (
		<footer className="bg-secondary-500">
			<div className="max-container text-white-500 padding-x">
				<div className="flex md:flex-row justify-between gap-16 md:gap-20 items-start max-lg:flex-col pt-[86px] border-b-[1px] border-white border-opacity-[.12] pb-14">
					<div className="flex flex-col gap-[30px] max-w-[460px]">
						<Link to="/" className="flex gap-2.5 items-center">
							<img
								src={getFileUrl(siteSettings?.footerLogo)}
								alt="Logo"
								width={64}
								height={69}
								className="m-0"
							/>
							<p className="text-[32px] font-bold -tracking-[1px]">
								{siteSettings?.officeName}
							</p>
						</Link>
						<p className="text-lg text-[#D9D9D9]">
							{removeHtml(footerCMS?.[0]?.description)}
						</p>
					</div>

					<div className="flex-1 flex flex-col md:flex-row justify-between gap-10 md:gap-33 w-full">
						{footerLinks.map((section, index) => (
							<ul key={index}>
								<h4 className="font-bold text-xl pb-10">{section.title}</h4>
								{section.links.map((item) => (
									<li
										className="text-lg mb-5 cursor-pointer text-[#D9D9D9] hover:text-white-500"
										key={item.name}
									>
										<Link to={item.link} onClick={() => window.scrollTo(0, 0)}>
											{item.name}
										</Link>
									</li>
								))}
							</ul>
						))}
						<div>
							<h4 className="font-bold text-xl pb-10">Follow us</h4>
							<ul>
								<Link to={siteSettings?.facebook}>
									<li className="footer-link hover:text-white group">
										<span className="footer-bg footer-icon-transition group-hover:bg-white-500 group-hover:text-purple-500">
											<FaFacebookF className="footer-icon" />
										</span>
										Facebook
									</li>
								</Link>
								<Link to={siteSettings?.twitter}>
									<li className="footer-link hover:text-white group">
										<span className="footer-bg footer-icon-transition group-hover:bg-white-500 group-hover:text-purple-500">
											<FaTwitter className="footer-icon" />
										</span>
										Twitter
									</li>
								</Link>
								<Link to={siteSettings?.linkedIn}>
									<li className="footer-link hover:text-white group">
										<span className="footer-bg footer-icon-transition group-hover:bg-white-500 group-hover:text-purple-500">
											<AiFillInstagram className="footer-icon" />
										</span>
										Instagram
									</li>
								</Link>
							</ul>
						</div>
					</div>
				</div>

				<div className="my-10">
					<h4 className="text-xl font-bold text-center mb-4">
						{footerCMS?.[1]?.title}
					</h4>
					<p className="text-sm text-[#D9D9D9] text-justify">
						{removeHtml(footerCMS?.[1]?.description)}
					</p>
				</div>
			</div>
			<div className="text-center text-[#D9D9D9] text-sm font-medium padding-x py-6 copyright-background">
				Copyright &copy; 2023 PS Cube | All Rights Reserved
			</div>
		</footer>
	);
};

export default Footer;
