import React, { useState, useRef } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';

const Testimonials = ({ testimonials, homeCMS }) => {
	const [, setSwiperInit] = useState(false);

	const PaginationRef = useRef(null);

	const breakpoints = {
		// when window width is >=320px
		320: {
			slidesPerView: 1,
			spaceBetween: 0,
		},
		// when window width is >=768px
		768: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1440: {
			slidesPerView: 3,
			spaceBetween: 24,
		},
	};

	// const pagination = {
	// 	clickable: true,
	// 	renderBullet: function (index, className) {
	// 		return '<span class="' + className + '">' + (index + 1) + '</span>';
	// 	},
	// };

	return (
		<div className="max-container padding-x py-32">
			<div className="flex flex-col items-center text-center w-full ">
				<p className="top-title">Testimonials</p>
				<h3 className="title">{homeCMS?.[7]?.title}</h3>
				<p className="sub-title md:max-w-xl">
					{removeHtml(homeCMS?.[7]?.description)}
				</p>
			</div>
			<div className="my-16">
				<Swiper
					slidesPerView={3}
					breakpoints={breakpoints}
					loop={true}
					pagination={{
						el: PaginationRef.current,
						clickable: true,
						bulletClass: 'testimonial-bullet',
						bulletActiveClass: 'testimonial-bullet-active',
						// renderBullet: function (_index, className) {
						// 	return `<span class="${className}"</span>`;
						// },
						renderBullet: (index, className) => {
							return '<span class="' + className + '">' + '</span>';
						},
					}}
					// pagination={pagination}
					modules={[Pagination, Autoplay]}
					autoplay={{
						delay: 2500,
						disableOnInteraction: true,
						// reverseDirection: true,
					}}
					onInit={() => setSwiperInit(true)}
				>
					{testimonials?.map((testimonial) => {
						const { id, name, companyName, title, description, image } =
							testimonial;
						return (
							<SwiperSlide key={id}>
								<div className="border hover:button-transition hover:border-purple-500 cursor-pointer rounded-xl py-11 px-9">
									<img
										src={getFileUrl(image)}
										alt="testimonialCard"
										height={56}
										width={56}
										className="rounded-full"
									/>
									<h3 className="mt-8 text-xl font-semibold">{title}</h3>
									<p className="sub-title mt-4">{description}</p>
									<div className="pt-8">
										<h4 className="text-purple-500 text-base font-semibold">
											{name}
										</h4>
										<p className="text-[16px] text-base mt-2">{companyName}</p>
									</div>
								</div>
							</SwiperSlide>
						);
					})}
					{/* Pagination */}
					<div
						className="testimonial-pagination mt-14"
						ref={PaginationRef}
					></div>
				</Swiper>
			</div>
		</div>
	);
};

export default Testimonials;
