import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { removeHtml } from '../utils/removeHtml';

const Stats = ({ homeCMS }) => {
	const stats = homeCMS?.slice(2, 6);
	const [animatedStats, setAnimatedStats] = useState([]);

	useEffect(() => {
		if (stats) {
			// Start animation for each stat
			const animationPromises = stats.map((stat) => {
				return new Promise((resolve) => {
					setTimeout(() => {
						resolve(stat);
					}, 500); // Adjust the timeout duration as needed
				});
			});

			// Trigger animations sequentially
			Promise.all(animationPromises).then((animatedValues) => {
				setAnimatedStats(animatedValues);
			});
		}
	}, [stats]);

	const bounceVariants = {
		rest: {
			scale: 1,
			transition: {
				duration: 0.3,
				ease: 'easeInOut',
			},
		},
		hover: {
			scale: 1.2,
			transition: {
				duration: 0.4,
				ease: 'easeInOut',
			},
		},
	};

	return (
		<div className="max-container padding-x pt-[104px] pb-[90px]">
			<div className="flex flex-col items-center text-center">
				<p className="top-title">Key Stats</p>
				<h3 className="title">{homeCMS?.[1]?.title}</h3>
				<p className="sub-title w-full lg:max-w-lg">
					{removeHtml(homeCMS?.[1]?.description)}
				</p>
			</div>
			<div className="flex flex-col sm:flex-row justify-center gap-8 xl:gap-16 mt-16">
				{animatedStats?.map((stat) => (
					<div
						key={stat?.id}
						className="text-center md:border-r-2 pr-8 xl:pr-16 last:border-none cursor-pointer"
					>
						<motion.div
							className="text-4xl font-bold text-purple-500 mb-2"
							variants={bounceVariants}
							whileHover="hover"
							whileTap="rest"
						>
							{stat?.title?.slice(0, -1)}
							<span className="text-purple-200">{stat?.title?.slice(-1)}</span>
						</motion.div>
						<p className="text-xl font-medium">
							{removeHtml(stat?.description)}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default Stats;
