import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import TextButton from './TextButton';

import parse from 'html-react-parser';
import { getFileUrl } from '../utils/imageURL';

const About = ({ aboutCMS }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const navigateToAbout = () => {
		window.scrollTo(0, 0);
		navigate('/about');
	};

	const description = aboutCMS?.[0]?.description;
	const parsedDesc = description ? parse(description) : '';

	const scaleVariants = {
		whileInView: {
			scale: [0, 1],
			opacity: [0, 1],
			transition: {
				duration: 1,
				ease: 'easeInOut',
			},
		},
	};

	return (
		<>
			<div className="flex flex-col lg:flex-row gap-16 max-container padding-y-23 padding-x">
				<motion.div
					// variants={scaleVariants}
					// whileInView={scaleVariants.whileInView}
					whileInView={{ x: [-100, 0], opacity: [0, 1] }}
					transition={{ duration: 0.5, ease: 'easeInOut' }}
					className="w-full lg:w-2/4 flex h-full overflow-hidden rounded"
				>
					<motion.img
						className="w-full h-full object-cover"
						src={getFileUrl(aboutCMS?.[0]?.image)}
						alt="about card"
					/>
				</motion.div>
				<motion.div
					whileInView={{ x: [100, 0], opacity: [0, 1] }}
					transition={{ duration: 1.6, ease: 'easeInOut' }}
					// transition={{ duration: 0.5, ease: 'easeInOut' }}
					className="lg:w-2/4 flex flex-col justify-center"
				>
					<p className="top-title">About Us</p>
					<h3 className="title">{aboutCMS?.[0]?.title}</h3>
					<div className="sub-title pb-8">{parsedDesc}</div>
					{location.pathname === '/' && (
						<TextButton label="Learn more" onClick={navigateToAbout} />
					)}
				</motion.div>
			</div>
		</>
	);
};

export default About;
