import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import TextButton from './TextButton';

import { getTeam } from '../services/home/home.services';
import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';
import LoadingSpinner from './LoadingSpinner';

const DEFAULT_PAGE_SIZE = 4;
const Team = ({ aboutCMS }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const navigateToAbout = () => {
		window.scrollTo(0, 0);
		navigate('/about');
	};
	// Queries
	const { data: team, isPending: teamLoading } = useQuery({
		queryKey: ['our-team'],
		queryFn: () => getTeam(0, DEFAULT_PAGE_SIZE),
	});

	if (teamLoading) {
		return <LoadingSpinner />;
	}
	return (
		<div
			className="flex flex-col md:flex-row justify-between gap-8 sm:gap-0 max-container padding-x pt-24 pb-16"
			style={{ overflowX: 'hidden' }}
		>
			<motion.div
				whileInView={{ x: [-100, 0], opacity: [0, 1] }}
				// initial={{ opacity: 0, x: '-100vw' }}
				// animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 0.5, ease: 'easeInOut' }}
				className="w-full md:w-2/4 lg:w-2/4 flex flex-col"
			>
				<p className="top-title">Our Team</p>
				<h3 className="title">{aboutCMS?.[1]?.title}</h3>
				<p className="sub-title pb-12">
					{removeHtml(aboutCMS?.[1]?.description)}
				</p>
				{location.pathname == '/' && (
					<TextButton label="Know More" onClick={navigateToAbout} />
				)}
			</motion.div>
			<motion.div
				whileInView={{ x: [100, 0], opacity: [0, 1] }}
				transition={{ duration: 1.5, ease: 'easeInOut' }}
				// initial={{ opacity: 0, x: '100vw' }}
				// animate={{ opacity: 1, x: 0 }}
				// transition={{ duration: 1.6, ease: 'easeInOut', delay: 0.5 }}
				className="flex justify-center gap-8 max-lg:gap-6 lg:w-2/4"
			>
				{team?.map((teamMember) => {
					const { id, name, image, designation } = teamMember;
					return (
						<div key={id} className="text-center">
							<div className="flex justify-center lg:justify-between aligns-center w-[150px] h-[150px] lg:w-[180px] lg:h-[180px] xl:w-[240px] xl:h-[240px] rounded-full bg-white-400">
								<img
									className="max-w-screen lg:w-full object-cover rounded-full"
									src={getFileUrl(image)}
									alt="team"
								/>
							</div>
							<p className="text-2xl font-semibold pt-8 pb-2.5">{name}</p>
							<p className="sub-title">{removeHtml(designation)}</p>
						</div>
					);
				})}
			</motion.div>
		</div>
	);
};

export default Team;
