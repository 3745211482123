import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { Hero, Stats, Industries, Testimonials } from '../sections';
import { Contact, About, Team, Company } from '../components';

// services
import { getCMS } from '../services/home/home.services';
import { getTestimonials } from '../services/home/home.services';
import LoadingSpinner from '../components/LoadingSpinner';

const DEFAULT_PAGE_SIZE = 4;
const home_slug = 'home-page';
const about_slug = 'about-page';

const Home = () => {
	// Queries
	const { data: homeCMS, isPending: homeCMSLoading } = useQuery({
		queryKey: ['home-cms', home_slug],
		queryFn: () => getCMS(home_slug, DEFAULT_PAGE_SIZE),
	});
	const { data: aboutCMS, isPending: aboutCMSLoading } = useQuery({
		queryKey: ['about-cms', about_slug],
		queryFn: () => getCMS(about_slug, DEFAULT_PAGE_SIZE),
	});
	const { data: testimonials, isPending: testimonialsLoading } = useQuery({
		queryKey: ['testimonials'],
		queryFn: () => getTestimonials(0, DEFAULT_PAGE_SIZE),
	});

	if (homeCMSLoading || aboutCMSLoading || testimonialsLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			{/* Hero Section */}
			<section className="bg-white-400">
				<Hero homeCMS={homeCMS} />
			</section>

			<Stats homeCMS={homeCMS} />

			<Industries homeCMS={homeCMS} />

			<About aboutCMS={aboutCMS} />
			<Team aboutCMS={aboutCMS} />
			<Company />

			<Testimonials homeCMS={homeCMS} testimonials={testimonials} />
			<Contact />
		</>
	);
};

export default Home;
