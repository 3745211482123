import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';

import { About, Company, Contact, Team } from '../components';

// services
import { getCMS, getCMSPageTitle } from '../services/home/home.services';
import { removeHtml } from '../utils/removeHtml';
import { getFileUrl } from '../utils/imageURL';
import LoadingSpinner from '../components/LoadingSpinner';

import { Helmet } from 'react-helmet-async';

const DEFAULT_PAGE_SIZE = 4;
const about_slug = 'about-page';

const AboutUs = () => {
	// Queries
	const { data: aboutCMS, isPending: aboutCMSLoading } = useQuery({
		queryKey: ['about-cms', about_slug],
		queryFn: () => getCMS(about_slug, DEFAULT_PAGE_SIZE),
	});
	const { data: pageTitleCMS, isPending: pageTitleCMSLoading } = useQuery({
		queryKey: ['cms-pageTitle', about_slug],
		queryFn: () => getCMSPageTitle(about_slug, DEFAULT_PAGE_SIZE),
	});

	if (aboutCMSLoading || pageTitleCMSLoading) {
		return <LoadingSpinner />;
	}

	return (
		<>
			<Helmet>
				<title>{`PSUBE | ${pageTitleCMS?.title}`}</title>
			</Helmet>
			<div className="bg-white-400">
				<div className="max-container padding-x pt-[72px] pb-10 text-center">
					<p className="top-title">Know More</p>
					<h3 className="title">About PS Cube</h3>
				</div>
			</div>

			<About aboutCMS={aboutCMS} />
			<Team aboutCMS={aboutCMS} />

			{/* Who we Are */}
			<div
				className="flex flex-col justify-between gap-10 max-container padding-x py-[128px] 
						lg:flex-row lg:gap-0"
			>
				<motion.div
					whileInView={{ x: [-100, 0], opacity: [0, 1] }}
					transition={{ duration: 1, ease: 'easeInOut' }}
					className="flex w-full h-[480px] md:h-[648px] lg:w-2/4 "
				>
					<motion.img
						src={getFileUrl(aboutCMS?.[2]?.image)}
						className="rounded-xl h-full object-cover"
						alt="about"
					/>
				</motion.div>
				<motion.div
					whileInView={{ x: [100, 0], opacity: [0, 1] }}
					transition={{ duration: 1.6, ease: 'easeInOut' }}
					className="flex flex-col justify-center w-full lg:w-5/12"
				>
					<h3 className="title">{aboutCMS?.[2]?.title}</h3>
					<p className="sub-title">{removeHtml(aboutCMS?.[2]?.description)}</p>
				</motion.div>
			</div>

			<Company />
			<Contact />
		</>
	);
};

export default AboutUs;
